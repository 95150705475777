
import { Options } from 'vue-class-component'
import { ICommonSearch, IProduct } from 'smartbarcode-web-core/src/utils/types/index'
import { FETCH_PRODUCTS } from '@/store/actions'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { Mixins } from 'vue-property-decorator'
import PageHeader from '@/components/common/PageHeader.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import debounce from 'lodash/debounce'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'

@Options({
  components: { PageHeader, ItemNotFound },
  name: 'Product',
})
export default class Product extends Mixins(DataTableMixin) {
  loading = false
  dataSearch = {} as ICommonSearch

  get getItems(): IProduct[] {
    return this.$store.getters.getProducts
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    this.displayItems = this.getItems.filter(
      (prod: IProduct) =>
        this.cleanString(prod.code).includes(cleanSearchText) ||
        this.cleanString(prod.name).includes(cleanSearchText) ||
        this.cleanString(prod.supplier.companyName).includes(cleanSearchText) ||
        this.cleanString(prod.supplier.postalCode).includes(cleanSearchText) ||
        this.cleanString(prod.supplier.address1).includes(cleanSearchText) ||
        this.cleanString(String(prod.dimension.weight)).includes(cleanSearchText)
    )

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  onAddProduct() {
    this.$router.push({
      name: 'product-detail',
      params: {
        action: 'add',
      },
    })
  }

  onEdit(prod: IProduct) {
    this.$router.push({
      name: 'product-detail',
      params: {
        action: 'edit',
        product: JSON.stringify(prod),
      },
    })
  }

  async fetchList() {
    try {
      this.loading = true
      await this.$store.dispatch(FETCH_PRODUCTS)
    } catch (e) {
    } finally {
      this.loading = false
    }

    this.filterItems()
  }

  async created() {
    this.routeName = 'product'
    this.getQueryData()

    if (!this.$store.state.product.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
