<template>
  <div class="flex flex-col h-full" v-loading.fullscreen="loading">
    <div class="flex flex-row pb-8 border-b border-gray-400 border-solid header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader :title="$t('product.product_list')" @load:refreshList="fetchList" />
      </div>
      <div class="items-center flex flex-row header-container__right">
        <el-button type="primary" icon="el-icon-plus" size="medium" @click="onAddProduct()">
          {{ $t('product.new_product') }}
        </el-button>
        <div class="ml-8">
          <el-input
            prefix-icon="el-icon-search"
            size="medium"
            v-model="dataSearch.text"
            @keyup="refreshItemList"
            @blur="updateTextSearch"
          />
        </div>
      </div>
    </div>

    <!-- address book table -->
    <div v-if="displayItems.length > 0" class="flex-grow flex flex-col">
      <div class="my-16 flex-grow table-container">
        <el-table
          :data="displayItems"
          class="rounded-xl w-full"
          header-cell-class-name="custom-table__header-cell"
          cell-class-name="custom-table__cell cursor-pointer"
          border
          :empty-text="$t('empty')"
          highlight-current-row
          @current-change="onEdit"
        >
          <el-table-column prop="code" :label="$t('product.product_code')" width="180"> </el-table-column>
          <el-table-column prop="name" :label="$t('Name')" width="280" />
          <el-table-column :label="$t('product.supplier_name')" width="180">
            <template v-slot="scope">
              <div>{{ scope.row.supplier.companyName }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('product.supplier_postal_code')" width="180">
            <template v-slot="scope">
              <div>{{ scope.row.supplier.postalCode }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('product.supplier_address')">
            <template v-slot="scope">
              <div>{{ scope.row.supplier.address1 }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('product.weight')" width="180">
            <template v-slot="scope">
              <div>{{ scope.row.dimension.weight }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="justify-center flex flex-row mb-16 paging-container">
        <el-pagination
          layout="prev, pager, next"
          :total="getItems.length"
          :page-size="itemsPerPage"
          :current-page="currentPageNum"
          @current-change="currentPageChange($event)"
          background
          class="custom-pagination"
        >
        </el-pagination>
      </div>
    </div>

    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { ICommonSearch, IProduct } from 'smartbarcode-web-core/src/utils/types/index'
import { FETCH_PRODUCTS } from '@/store/actions'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { Mixins } from 'vue-property-decorator'
import PageHeader from '@/components/common/PageHeader.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import debounce from 'lodash/debounce'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'

@Options({
  components: { PageHeader, ItemNotFound },
  name: 'Product',
})
export default class Product extends Mixins(DataTableMixin) {
  loading = false
  dataSearch = {} as ICommonSearch

  get getItems(): IProduct[] {
    return this.$store.getters.getProducts
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    this.displayItems = this.getItems.filter(
      (prod: IProduct) =>
        this.cleanString(prod.code).includes(cleanSearchText) ||
        this.cleanString(prod.name).includes(cleanSearchText) ||
        this.cleanString(prod.supplier.companyName).includes(cleanSearchText) ||
        this.cleanString(prod.supplier.postalCode).includes(cleanSearchText) ||
        this.cleanString(prod.supplier.address1).includes(cleanSearchText) ||
        this.cleanString(String(prod.dimension.weight)).includes(cleanSearchText)
    )

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  onAddProduct() {
    this.$router.push({
      name: 'product-detail',
      params: {
        action: 'add',
      },
    })
  }

  onEdit(prod: IProduct) {
    this.$router.push({
      name: 'product-detail',
      params: {
        action: 'edit',
        product: JSON.stringify(prod),
      },
    })
  }

  async fetchList() {
    try {
      this.loading = true
      await this.$store.dispatch(FETCH_PRODUCTS)
    } catch (e) {
    } finally {
      this.loading = false
    }

    this.filterItems()
  }

  async created() {
    this.routeName = 'product'
    this.getQueryData()

    if (!this.$store.state.product.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }
}
</script>
